<template>
  <div class="details-box">
    <p class="qty d-flex align-start text-start mb-2 text-subtitle-1">
      Quantity:&nbsp;
      <b>
        {{ getValue("items_count", 0) }}
        {{ getValue("items_count", 0) > 1 ? 'items' : 'item' }}
        </b
      >
    </p>
    <p class="mrp d-flex align-start text-start mb-2 text-subtitle-1">
      MRP:&nbsp; <b>₹{{ formatCurrency(getValue("mrp")) }} </b>
    </p>

    <p class="mrp d-flex align-start text-start mb-2 text-subtitle-1">
      Floor Price:&nbsp; <b>₹{{ formatCurrency(getValue("floor_price")) }} </b>
    </p>
    <p class="bid d-flex align-start text-start mb-4 text-subtitle-1" v-if="user?.authentication_token">
      My Bid:&nbsp; 
      <b v-bind:class="getValue('color')">
        {{ getValue("bid_price") }}&nbsp;{{ getRank() }}
      </b>
    </p>
  </div>
  <div class="d-flex justify-space-between mt-2 mb-2">
    <p>
      <v-icon size="x-large" icon="mdi-truck-outline" color="blue"></v-icon>
      {{getValue('shipping')}}
    </p>
  </div>
  <template v-if="bid_not_started">
    <v-btn color="blue-darken-1 flex-grow-1 mb-2" size="large" width="100%">
      Bid not started
    </v-btn>
  </template>
  <template v-else-if="!bid_finished">
    <div class="mt-3">
        <v-text-field
          v-if="!isBidEqualToBuy()"
          v-model="lotDetailState.bidPrice"
          :error="bid_price_error ? true : false"
          label="Enter Bid"
          prepend-inner-icon="mdi-currency-rupee"
          variant="outlined"
          @input="handleBidPrice"
        ></v-text-field>
        <span class="error-message">{{ bid_price_error }}</span>
        <v-btn
          v-if="!isBidEqualToBuy()"
          color="blue-darken-1 flex-grow-1 mb-2"
          size="large"
          width="100%"
          :disabled="!lotDetailState.bidPrice || bid_price_error !== ''"
          class="bid-buy-button text-caption mt-2 mb-2"
          variant="flat"
          @click="handleBidBuyConfirm('bid')"
          >SUBMIT BID</v-btn
        >

      <v-btn
        color="blue"
        variant="outlined"
        class="bid-buy-button text-caption mb-2"
        size="large"
        width="100%"
        @click="handleBidBuyConfirm('buy')"
      >
        Buy @₹{{ formatCurrency(getValue("buy_now_price", "N/A")) }} &nbsp;<b> (Save
        {{ getValue("offPercentage", 0) }}%)</b>
      </v-btn>
    </div>
  </template>
  <template v-else-if="bid_finished">
    <v-btn color="blue-darken-1 flex-grow-1 mb-2" size="large" width="100%">
      Bid Expired
    </v-btn>
  </template>

  <v-btn color="green-darken-1 flex-grow-1 mb-2" size="large" width="100%" @click="setShareActive">
    <v-icon size="x-large" icon="mdi-share" color="white"></v-icon>
    Share
  </v-btn>
</template>

<script setup>
import { defineProps, computed, watch, ref } from "vue";
import {
  uselotProducts,
  useAuth
} from "../../../stores";
import {
  statusList,
} from "../../../static";
import { formatCurrency } from "../../../misc/formatter.js";

const lotStore = uselotProducts();
const lotSummary = computed(() => lotStore.lotSummary);

const authStore = useAuth();
const user = computed({
  get: () => authStore.user,
});

let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
let EndBidTime = new Date(lotSummary.value?.end_date).getTime();

const bid_finished = ref(
  lotSummary.value?.status === statusList.InProgress ||
  lotSummary.value?.status === statusList.InProgress_2 ||  
  lotSummary.value?.status === statusList.NotStarted
    ? !lotSummary.value.bidRemainingTime
    : true
);

const bid_not_started = ref(
  lotSummary.value?.status === statusList.NotStarted ||
  lotSummary.value.futureBid
);


const props = defineProps({
  getValue: Object,
  getRank: Object,
  lotDetailState: Object,
  setShareActive: Function,
  handleBidPrice: Function,
  bidPriceCheck: Function,
  isBidEqualToBuy: Function,
  handleBidBuyConfirm: Function,
});

const bid_price_error = computed(() =>
  lotStore.error?.id === "bid_price_error"
    ? lotStore.error?.message
    : props.lotDetailState.bidPrice
    ? props.bidPriceCheck()
    : ""
);

watch(() => ({...lotSummary.value}), async (newValue, oldValue) => {
  if (lotSummary.value?.id) {
    let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
    let EndBidTime = new Date(lotSummary.value?.end_date).getTime();
    
    bid_not_started.value =
      (lotSummary.value?.status === statusList.NotStarted) || (lotSummary.value.futureBid);
    bid_finished.value =
      ((lotSummary.value?.status === statusList.InProgress) ||
      (lotSummary.value?.status === statusList.InProgress_2) ||
      (lotSummary.value?.status === statusList.NotStarted))
        ? !lotSummary.value.bidRemainingTime
        : true;
  }
});

const {
  getValue = () => {},
  getRank = () => {},
  bidPriceCheck = () => {},
  lotDetailState,
  handleBidPrice = () => {},
  setShareActive = () => {},
  isBidEqualToBuy = () => {},
  handleBidBuyConfirm = () => {},
} = props;
</script>
