<template>
  <v-row>
    <v-col cols="12">
      <v-card class="lots-box" variant="none">
        <v-card-title
          class="font-weight-bold d-flex align-start text-start text-color pl-5 bck-color sm-padding"
          >LOT DETAILS</v-card-title
        >

        <v-row class="bck-color pt-0 pa-5">
          <v-col
            col="12"
            md="6"
            lg="6"
            xl="6"
            sm="12"
            class="bck-color sm-flex-basis"
          >
            <v-sheet class="pa-4 h-100">
              <v-row>
                <v-col cols="12" md="4" lg="4" xl="4" sm="12">
                  <v-sheet>TOP BRAND</v-sheet>
                </v-col>
                <v-col cols="6" md="4" lg="4" xl="4" sm="6" class="text-xl-end"
                  ><v-sheet>
                    <span class="blue-box"></span> Quantity</v-sheet
                  ></v-col
                >
                <v-col cols="6" md="4" lg="4" xl="4" sm="6" class="text-xl-end">
                  <v-sheet> <span class="green-box"></span> Lot MRP</v-sheet>
                </v-col>
              </v-row>
              <div>
                <v-row v-for="brand in brands" :key="brand.id">
                  <v-col
                    cols="12"
                    md="2"
                    lg="2"
                    xl="2"
                    sm="12"
                    class="d-flex align-start text-start text-capitalize"
                  >
                    {{ brand.brand_name }}
                  </v-col>
                  <v-col cols="6" md="5" lg="5" xl="5" sm="6">
                    <label class="d-flex align-end text-end"
                      >{{ brand.item_count }}({{
                        brand.item_percentage
                      }}%)</label
                    >
                    <v-progress-linear
                      :model-value="brand.item_percentage"
                      bg-color="blue"
                      color="blue"
                      height="7"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="6" md="5" lg="5" xl="5" sm="6">
                    <label class="d-flex align-end text-end"
                    >₹{{ formatCurrency(brand.sum) }}({{ brand.percentage }}%)</label
                    >
                    <v-progress-linear
                      :model-value="brand.percentage"
                      bg-color="green"
                      color="green"
                      height="7"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
          </v-col>
          <v-col col="12" md="6" lg="6" xl="6" sm="12">
            <v-sheet class="pa-4 h-100">
              <v-row>
                <v-col cols="12" md="4" lg="4" xl="4" sm="12">
                  <v-sheet>TOP CATEGORY</v-sheet>
                </v-col>
                <v-col cols="6" md="4" lg="4" xl="4" sm="6" class="text-xl-end">
                  <v-sheet> <span class="blue-box"></span> Quantity</v-sheet>
                </v-col>
                <v-col cols="6" md="4" lg="4" xl="4" sm="6" class="text-xl-end">
                  <v-sheet> <span class="green-box"></span> Lot MRP</v-sheet>
                </v-col>
              </v-row>
              <div>
                <v-row v-for="category in categorys" :key="category.id">
                  <v-col
                    cols="12"
                    md="4"
                    lg="4"
                    xl="4"
                    sm="12"
                    class="d-flex align-start text-start"
                  >
                    {{ category.category_name }}
                  </v-col>
                  <v-col cols="6" md="4" lg="4" xl="4" sm="6">
                    <label class="d-flex align-end text-end"
                      >{{ category.item_count }}({{
                        category.item_percentage
                      }}%)</label
                    >
                    <v-progress-linear
                      :model-value="category.item_percentage"
                      bg-color="blue"
                      color="blue"
                      height="7"
                    ></v-progress-linear>
                  </v-col>
                  <v-col cols="6" md="4" lg="4" xl="4" sm="6">
                    <label class="d-flex align-end text-end"
                      >₹{{ formatCurrency(category.sum) }}({{ category.percentage }}%)</label
                    >
                    <v-progress-linear
                      :model-value="category.percentage"
                      bg-color="green"
                      color="green"
                      height="7"
                    ></v-progress-linear>
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { uselotProducts } from "../../stores";
import { formatCurrency } from "../../misc/formatter.js";
const lotStore = uselotProducts();
const brands = computed(() => lotStore.top_Brand);
const categorys = computed(() => lotStore.top_Category);
</script>
