<template>
  <div class="text-center">
    <v-dialog class="share-dialog" v-model="dialog.popUp">
      <v-card>
        <v-card-text>
          <v-btn icon variant="text" @click="dialog.popUp = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          Share Options
          <div class="d-flex">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1 flex-grow-1 mb-2"
                variant="outlined"
                @click="openEmail"
              >
                <v-icon>mdi-email</v-icon>
                Email
              </v-btn>
              <v-btn
                color="green-darken-1 flex-grow-1 mb-2"
                variant="outlined"
                @click="openWhatsapp"
              >
                <v-icon>mdi-whatsapp</v-icon>
                Whatsapp
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="dialog.popUp = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, defineProps, reactive, watch } from "vue";
const props = defineProps({
  dialog: Object,
  lotName: String,
  setShareActive: Function
});

const {
  dialog
} = props;

const openWhatsapp = () => {
  props.dialog.popUp = false
  window.open('https://api.whatsapp.com/send?text=' + window.location.href)
}

const openEmail = () => {
  dialog.popUp = false
  const url = `mailto:support@b4traders.com?subject=Email Lot - ${props.lotName.replaceAll(/&/g, '%26').replaceAll('=', '%3D')}&body=Hi,%0A%0APlease%20check%20out%20Lot%20for%20sale.%0A%20${props.lotName.replaceAll(/&/g, '%26').replaceAll('=', '%3D')}%0A%0Afrom%20URL:%20-%20${window.location.href.replaceAll('=', '%3D').replaceAll(/&/g, '%26')}%0A%0AThanks,%0A`
  window.open(url)
}
</script>
