<template>
  <v-col cols="12" md="2" lg="2" xl="2" sm="12" xs="12" class="h-50">
    <v-card class="feedback-card" variant="outlined">
      <v-card-text>
        <h2 class="d-flex align-start text-start font-weight-bold">
          Interested in the LOT but not bidding?
        </h2>
        <h2 class="d-flex align-start font-weight-bold">
          Please let us know why...
        </h2>
      </v-card-text>
      <div class="pa-2" v-if="lotDetailState.buyer_feedback">
        <h1>{{ lotDetailState.buyer_feedback }}</h1>
      </div>
      <div class="pa-2 lot-feedback__reason-option" v-if="!lotDetailState.buyer_feedback">
        <p class="d-flex align-start font-weight-bold">Select any one reason</p>

        <v-btn-toggle
          v-model="lotDetailState.feedBack"
          divided
          density="compact"
          class="d-block h-auto"
          vertical
        >
          <p
            class="mb-2 lot-feedback__option-list"
            v-for="feedBackOption in feedBackOptions"
            :key="feedBackOption.label"
          >
            <v-btn
              class="d-flex text-center align-center text-caption py-2"
              variant="outlined"
              :value="feedBackOption.value"
              >{{ feedBackOption.label }}
              <v-icon
                icon="mdi-close"
                v-if="feedBackOption.value === lotDetailState.feedBack"
                color="blue"
              ></v-icon>
            </v-btn>
          </p>
        </v-btn-toggle>
        <p>
          <v-btn
            color="mb-2"
            class="d-flex bg-blue text-center align-center text-caption"
            variant="flat"
            :disabled="!lotDetailState.feedBack"
            @click="handleSubmitFeedBack"
          >
            Submit Feedback</v-btn
          >
        </p>
      </div>
    </v-card>
  </v-col>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  lotDetailState: Object,
  feedBackOptions: Object,
  handleSubmitFeedBack: Function
});

const { lotDetailState = {}, feedBackOptions = [], handleSubmitFeedBack = () => {} } = props;
</script>
