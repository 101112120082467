<template>
  <!-- web responsive -->
   <v-card class="product-details d-lg-block d-md-block d-sm-block d-xs-none d-none" variant="none">
    <v-card-text class="py-3 bg-white terms-conditions">
          <div >
          <h2 class="d-flex justify-center align-center text-center section-title">Item Condition</h2>
          <p class="d-flex justify-center align-center text-center desc">BULK4TRADERS offers products with clearly defined conditions. Please read below for
            full
            explanations of the different product conditions we offer</p>
          </div>
          <v-row v-for="(itemCondition, index) in itemConditions" :key="index" class="justify-center sm-text-align mt-4 items-type">
            <v-col cols="3">
              <v-img :src="itemCondition.image" alt="no img" width="250" height="250" class="mx-auto" />
            </v-col>
            <v-col cols="9">
              <h3 class="mb-4 mt-3 title">{{ itemCondition.title }}</h3>
              <p class="type">{{ itemCondition.description }}
              </p>
            </v-col>
          </v-row>
          <v-row class="justify-center sm-text-align mt-4 items-type">
            <a
              @click="props.handleReadMore('itemConditionElement', true)"
              class="cursor-pointer"
            >Read Less</a>
          </v-row>
    </v-card-text>
  </v-card>

  <!-- mobile responsive -->
   <v-card class="product-details terms-conditions d-lg-none d-md-none d-sm-none d-xs-block d-block" variant="none">
    <v-card-text class="py-3 bg-white">
          <div >
          <h2 class="d-flex justify-center align-center text-center section-title">Item Condition</h2>
          <p class="d-flex desc">BULK4TRADERS offers products with clearly defined conditions. Please read below for
            full
            explanations of the different product conditions we offer</p>
          </div>
          <v-row v-for="(itemCondition, index) in itemConditions" :key="index" class="justify-center sm-text-align mt-4 items-type">
            <v-col cols="12">
              <v-img :src="itemCondition.image" alt="no img" width="250" height="250" class="mx-auto" />
            </v-col>
            <v-col cols="12">
              <h3 class="mb-4 mt-3 title">{{ itemCondition.title }}</h3>
              <p class="type">{{ itemCondition.description }}
              </p>
            </v-col>
          </v-row>
          <v-row :key="index" class="sm-text-align mt-4 items-type">
            <p>
            <a
              @click="props.handleReadMore('itemConditionElement', true)"
              class="cursor-pointer"
            >Read Less</a></p>
          </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { itemConditions } from "./index.js";
import { defineProps } from "vue";

const props = defineProps({
  handleReadMore: Function
})
</script>