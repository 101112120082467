<template>
  <div class="details-box">
    <v-row class="moq-details">  
        <v-col sm="7" md="7" lg="8" cols="6">
        <p class="price">
            Total Price <br />
            <span>₹ {{formatCurrency(getPrice())}} ({{getOffPer()}})</span>
        </p>

        </v-col>
        <v-col sm="5" md="5" lg="4" cols="6" class="pd-none">
            <v-div class="lot-options">
              <fieldset class="bid-filter">
                <legend>No of Lots</legend>
                  <v-selector :disabled="lotItems.length < 1" v-model="lotsQty" :options="lotItems" :clearable="false"></v-selector>
              </fieldset>
            </v-div>
        </v-col>
    </v-row>
    <v-row class="lot-quantity">
        <v-col cols="3 d-flex px-0" v-for="(item, index) in moq_lot_pricings" :key="index">
            <div v-if="maxLots >= item.from_quantity" :class="activeLot === index ? `quantity-box active` : `quantity-box`">
                <p class="qty">Quantity {{item.from_quantity}} to {{item.to_quantity}}</p>
                <p class="off">
                  <v-icon size="medium" icon="mdi-tag" color="blue"></v-icon>
                  {{getLotOff(item?.price)}} off
                </p>
            </div>
        </v-col>
    </v-row>

    <p class="price mt-6">
      MRP Total (Per Lot): &nbsp;&nbsp; <b>₹{{ formatCurrency(getValue("mrp").toString()) }} </b>
    </p>
  </div>
  <div class="d-flex justify-space-between mt-2 mb-2">
    <p>
      <v-icon size="x-large" icon="mdi-truck-outline" color="blue"></v-icon>
        {{getValue('shipping')}}
    </p>
  </div>
  <template v-if="bid_not_started">
    <v-btn color="blue-darken-1 flex-grow-1 mb-2" size="large" width="100%">
      Bid not started
    </v-btn>
  </template>
  <template v-else-if="!bid_finished">
    <div class="mt-3">
      <v-btn
        color="blue-darken-1 flex-grow-1 mb-2"
        variant="flat"
        class="bid-buy-button text-caption primary-btn"
        size="large"
        width="100%"
        @click="handleSubmit"
      >
        Buy @ ₹{{ formatCurrency(getPrice()) }} &nbsp;<b>(Save {{getOffPer()}})</b>
      </v-btn>
    </div>
  </template>
  <template v-else-if="bid_finished">
    <v-btn color="blue-darken-1 flex-grow-1 mb-2" size="large" width="100%">
      Bid Expired
    </v-btn>
  </template>
  <v-btn color="green-darken-1 flex-grow-1 mb-2" size="large" width="100%" @click="setShareActive">
    <v-icon size="x-large" icon="mdi-share" color="white"></v-icon>
    Share
  </v-btn>
</template>

<script setup>
import { defineProps, computed, ref, watch } from "vue";
import {
  uselotProducts,
  useAlertStore
} from "../../../stores";
import {
  statusList,
} from "../../../static";
import { formatCurrency, formatToNum } from "../../../misc/formatter";
const lotStore = uselotProducts();
const alertStore = useAlertStore();
const moq_lot_pricings = computed(() => lotStore.moq_lot_pricings);
const lotsQty = ref(1);
const lotItems = computed(() => {
  return Array((lotStore?.lotSummary?.available_quantity <= lotStore?.lotSummary?.maximum_lots_per_buyer) ? lotStore?.lotSummary?.available_quantity : lotStore?.lotSummary?.maximum_lots_per_buyer).fill(1).map((a,b) => a + b)
});
const activeLot = ref(0);

const lotSummary = computed(() => lotStore.lotSummary);
const maxLots = computed(() => lotStore?.lotSummary?.available_quantity <= lotStore?.lotSummary?.maximum_lots_per_buyer ? lotStore?.lotSummary?.available_quantity : lotStore?.lotSummary?.maximum_lots_per_buyer);

let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
let EndBidTime = new Date(lotSummary.value?.end_date).getTime();

const bid_finished = ref(
  lotSummary.value?.status === statusList.InProgress ||
  lotSummary.value?.status === statusList.InProgress_2 ||
  lotSummary.value?.status === statusList.NotStarted
    ? !lotSummary.value.bidRemainingTime
    : true
);

const bid_not_started = ref(
  lotSummary.value?.status === statusList.NotStarted ||
  lotSummary.value.futureBid
);

const setLot = (val) => {
  activeLot.value = val;
}

const getPrice = () => {
  return ((lotsQty.value * (moq_lot_pricings.value[activeLot.value]?.price))) 
}

const getLotOff = (price = 0) => {
    const off = (parseInt(price) * 100) / ((props.getValue("mrp").toString()));   
    return Math.floor((100 - off)) + "%"
}

const getOffPer = () => {
    const off = (moq_lot_pricings.value[activeLot.value]?.price * 100) / ((props.getValue("mrp")));
    return Math.floor((100 - off)) + "%"
}

const props = defineProps({
  getValue: Object,
  setShareActive: Function,
  bidPriceCheck: Function,
  handleBidBuyConfirm: Function,
});

const handleSubmit = () => {
  props.handleBidBuyConfirm('moq', lotsQty.value, getPrice())
}

watch(lotsQty, () => {
  let qtyOption = moq_lot_pricings.value.find(g => g.from_quantity <= lotsQty.value && g.to_quantity >= lotsQty.value);
  activeLot.value = moq_lot_pricings.value.indexOf(qtyOption);
})

watch(() => ({...lotSummary.value}), async (newValue, oldValue) => {
  if (lotSummary.value?.id) {
    let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
    let EndBidTime = new Date(lotSummary.value?.end_date).getTime();
    lotsQty.value = 1;
    
    bid_not_started.value =
      lotSummary.value?.status === statusList.NotStarted ||
      lotSummary.value.futureBid;
    bid_finished.value =
      lotSummary.value?.status === statusList.InProgress ||
      lotSummary.value?.status === statusList.InProgress_2 ||
      lotSummary.value?.status === statusList.NotStarted
        ? !lotSummary.value.bidRemainingTime
        : true;
  }
});

const {
  getValue = () => {},
  bidPriceCheck = () => {},
  setShareActive = () => {},
  handleBidBuyConfirm = () => {},
} = props;
</script>
