<template>
  <p><strong>Blubirch</strong></p>
  <p><strong>Lot Level Simplified T&amp;Cs for Blubirch Lots</strong></p>
  <p><strong>Bid Value</strong></p>
  <p>
    Bid value is inclusive of GST however it doesn&rsquo;t include the logistics
    cost.
  </p>
  <p><strong>Cancellation</strong></p>
  <p>
    Once a you have won the lot you have to honour the payment. Failure in doing
    so could result in penalty and/or &ldquo;blacklisting&rdquo; on the
    platform. Blubirch (Seller) have the right, at our sole discretion, to
    refuse or cancel any auctioned lot or part of it for any reason. In the
    event that an auctioned lot be cancelled, we will notify you by
    email/phone/SMS/WhatsApp of such cancellation and your payment will be
    refunded within 24 hours.
  </p>
  <p><strong>Payment Terms & Purchase</strong></p>
  <p>
    Once your bid is approved and you are declared the winner of the relevant bid, you would be required to pay 40% of the total bid price/value within a period of 24 hours from the time of approval of your bid, failing which the bid shall stand cancelled
  </p>
  <p>
    You will be required to pay 60% of the amount representing the bid price/value within 24 hours from the time of communication from our side requiring you to pay the remaining 60%. In case you do not pay the remaining 60% amount within 7 days from the date of communication for the same from our side, the bid shall stand cancelled and we will be liable to return only an amount equal to 20% of the bid price/value paid by you in advance, and retain the remaining 20% as cancellation charges. The said amount equivalent to 20% of the bid price/value shall stand forfeited.
  </p>
  <p>
    Subject to you making the payment of the remaining 60% of bid price/value within the above-mentioned 7 day period, in case you do not pick up the goods on the date specified by us to you, you may collect the same only within 7 days from the date of collection specified by us by paying such logistics, warehousing and holding charges as specified by us. In case you fail to  pick-up  the  goods  within  the  above-mentioned  7  day  period,  then  the  bid  shall  stand cancelled and we will be entitled to retain the 20% of the total bid price/value paid by you as cancellation charges, and shall return the remaining amount to you. The said amount equivalent to 20% of the bid price/value shall stand forfeited
  </p>


  <p><strong>Item Condition (Definitions)</strong></p>
  <p><u>Brand New</u></p>
  <p>
    A brand-new, unused, unopened item in its original packaging, with all
    original packaging materials included. Packaging might have minor scratches
    or damages. Since it is seal packed, functional &amp; physical condition of
    the item is not checked by Bulk4Traders team
  </p>
  <p><u>Open Box</u></p>
  <p>
    An apparently untouched item in perfect condition and fully functional. The
    original packaging is intact but could have minor scratches or damages.
    There are absolutely no signs of wear on the item.The product has been
    tested by the Bulk4Traders team
  </p>
  <p><u>Very Good</u></p>
  <p>
    The product is well-cared-for and is fully functional but may show minor
    physical or cosmetic blemishes and/ or the item may have some accessories
    missing. The packaging might have been replaced to protect the item. The
    product has been tested by the Bulk4Traders team
  </p>
  <p><u>Good</u></p>
  <p>
    The item shows normal marks from consistent use, but it remains in good condition and works fully.  It  may  show  other  signs  of  previous  use  and/  or  the  item  may  have  some  accessories missing. The packaging might be missing or might have major damages. The product has been tested by the Bulk4Traders team.
  </p>
  <p><u>Acceptable</u></p>
  <p>
    The product may have minor functional issues and/ or the item is fairly worn. Signs of wear can include aesthetic issues such as scratches, dents, worn corners and cracks/damage on body. The item may have identifying markings on it or show other signs of previous use. Packaging may or may not be present and packaging condition may be bad. The product has been tested by the Bulk4Traders team.
  </p>
  <p><u>Defective</u></p>
  <p>
    The product may be functionally defective and/ or physically damaged. Packaging may or may not  be  present  and  packaging  condition  may  be  bad.  The  product  has  been  tested  by  the Bulk4Traders team.
  </p>
  <p><u>Not Tested</u></p>
  <p>
    These items have not been inspected by Bulk4Traders team or have been physically inspected but not functionally checked. These are being sold in “As-is” condition. Some of these items could  be  working  order  while  some  may  not  be  fully  functional.  These  may  not  come  with original packaging, manuals and/or supplementary accessories such as batteries and chargers
  </p>
  <p><strong>Shipment</strong></p>
  <p>
    Post  payment  for  the  won  lot,  buyer  will  receive  the  invoice  and  information  for  inventory pickup. Logistics cost has to be borne by the buyer over and above the bid value.
  </p>
  <p><strong>Claims:&nbsp;</strong></p>
  <p><strong>Self Pickup</strong></p>
  <p>
    Claims can be filed ONLY for shortage of items when the inventory is being
    picked up from the Blubirch warehouse. The buyer cannot file claims for any
    other situations such as grade mismatch or item mismatch, however, he can
    reject such units during pickup and these will not be invoiced by Blubirch.
  </p>
  <p>
    The buyer or buyer&rsquo;s representative should clearly account for all the
    items included in the winning lot and what they are picking up. If there is
    a shortage of units, the buyer should inform Blubirch representative
    immediately. The details of the shortage such as number and type of shortage
    should be noted on the invoice document given to the buyer at the pick-up
    location itself.&nbsp;
  </p>
  <p><strong>3P Dispatch</strong></p>
  <p>
    If 3P logistics is used for shipping the units from Blubirch to buyer, no
    claim in any form will be entertained.&nbsp;
  </p>
  <p><strong>Claim Procedure:</strong></p>
  <p>To make a claim, please follow the following process:</p>
  <ol>
    <li>
      As noted above, the buyer should have the details of the discrepancy
      clearly noted in the invoice provided by Blubirch
    </li>
    <li>Invoice should be signed by the Blubirch Warehouse Point of Contact</li>
    <li>
      Email the signed invoice to the Blubirch Customer Service Team on
      <a href="mailto:support@b4traders.com">support@b4traders.com</a> and get
      the confirmation before leaving Blubirch&rsquo;s warehouse
    </li>
    <li>Blubirch will refund the money within 10-12 working days.</li>
  </ol>
</template>
