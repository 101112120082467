<template v-if="timerCount?.seconds">
  <span>
    <span>{{timerCount.day}} </span>&nbsp;
    <span>{{timerCount.hour}}</span>&nbsp;
    <span>Hr</span>&nbsp;
    <span>{{timerCount.minutes}}</span>&nbsp;
    <span>Min</span>&nbsp;
    <span>{{timerCount.seconds}}</span>&nbsp;
    <span>Sec</span>
  </span>
</template>

<script setup>
import { defineProps, onMounted, onUnmounted, computed, ref,watch } from "vue";
import { useCommonStore, loaderStore } from "../../stores";
import { ConvertSectoDay } from "../../misc/formatter";

const props = defineProps({
  fetchDetails: Function,
  remainingTime: Number,
  endDate: String
})

const loader = loaderStore();
const counter = ref(props.remainingTime)
const timer = ref({});

onUnmounted(() =>  {
  clearInterval(timerInterval);
})

let timerInterval; 

const endDataReloadTimer = () => {
  timer.value = {day: '', hour: '00', minutes: '00', seconds: '00'} 
  timerInterval = setInterval(() => {
    // Get the current date and time
    const now = new Date().getTime();
    let endTime = new Date(props.endDate).getTime();
    let distance = endTime - now;
    let distanceSec = parseInt((new Date(props.endDate) - new Date()) / 1000);
    const { day, hour, minutes, seconds } = ConvertSectoDay(distanceSec)
    // Update the timer for this cardDetail
    if (endTime <= now) {
      timer.value = {day: '', hour: '00', minutes: '00', seconds: '00'} 
      if(props.fetchDetails) {
        loader.setLotLoading(true)
        props?.fetchDetails()
        clearInterval(timerInterval)
      }
    } else {
      let dayStr = day > 0 ? `${day} ${day > 1 ? 'Days' : 'Day'}` : '';
      timer.value = {day: dayStr, hour, minutes, seconds} 
    }
  }, 1000);
}

const reloadTimer = () => {
  timerInterval = setInterval(() => {
    if(counter.value > 1) {
      counter.value = counter.value - 1
    } else if(counter.value <= 1) {
      if(props.fetchDetails) {
        loader.setLotLoading(true)
        props?.fetchDetails()
        clearInterval(timerInterval)
      }
    }
  }, 1000)
}

const timerCount = computed(() => {
  if (props.remainingTime && props.remainingTime > 0) {
    const {
      day, hour, minutes, seconds
    } = ConvertSectoDay(parseInt(counter.value));

    const dayStr = day > 0 ? `${day} ${day > 1 ? 'Days' : 'Day'}` : '';
    return {day: dayStr, hour, minutes, seconds}
  } else {
    return timer.value
  }
})

const loadTimer = () => {
  const now = new Date().getTime();
  let endTime = new Date(props.endDate).getTime();
  if(props.remainingTime && props.remainingTime > 0) {
    reloadTimer();
  } else {
    timer.value = {hour: '00', minutes: '00', seconds: '00'}
  }
}

onMounted(() => {
  loadTimer()
})

watch(() => props.remainingTime, () => {
  clearInterval(timerInterval);
  counter.value = props.remainingTime;
  loadTimer()
});

</script>
