<template>
  <v-container class="ma-0 pa-0 filter-sticky footer-filter d-lg-none d-md-none">
    <v-row class="ma-0 pa-0">
      <!-- sort -->
      <v-col cols="6" class="pa-0 ">
        <div>
          <v-btn prepend-icon="mdi-sort-variant" elevation="4" width="100%" size="large" class="text-color"
            @click.stop="filterState.isOpen = !filterState.isOpen">Sort</v-btn>

          <v-navigation-drawer style="border-radius: 28px 28px 0px 0px" v-model="filterState.isOpen" location="bottom"
            temporary>
            <v-card class="rounded-borders pa-3" variant="flat" elevation="1">
              <div class="drag-handle"></div>
              <p class="pa-2">SORT BY</p>
            </v-card>
            
            <v-radio-group v-model="filterState.sortBy"> 
              <v-radio  color="blue" v-for="(item, index) in sortByOptions" :key="index"
                :label="item.title" :value="item.value" @click="sortLotProduct(item)"></v-radio>
            </v-radio-group>
          </v-navigation-drawer>
        </div>
      </v-col>

      <!-- filter -->
      <v-col cols="6" class="pa-0">
        <v-dialog v-model="filterState.dialog" fullscreen :scrim="false" transition="dialog-bottom-transition scale-transition" class="d-lg-none d-md-none d-block">
          <template v-slot:activator="{ props }">
            <v-btn class="text-color" v-bind="props" elevation="4" width="100%" prepend-icon="mdi-tune" size="large">
              Filter </v-btn>
          </template>

          <v-card>
            <v-toolbar :elevation="2" density="comfortable" color="#FFFFFF">
              <v-toolbar-title>Filters</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title class="text-color" @click="handleFilterClear">Clear All</v-toolbar-title>
            </v-toolbar>

            <v-row class="ma-0 pa-0 mobile-filter_sidebar">
              <v-col cols="5" class="mobile-filter_inner-sidebar overflow-auto h-100 pa-0">
                <v-list class="contentText-color" density="compact">
                  <v-list-item class="m-0" density="compact" title="BIDDING" @click="toggleItem(0)"></v-list-item>
                  <v-list-item class="m-0" density="compact" title="PRICING RANGE" @click="toggleItem(1)"></v-list-item>
                  <v-list-item class="m-0" density="compact" title="CATEGORY" @click="toggleItem(2)" v-if="categoryElements.length > 0"></v-list-item>
                  <v-list-item class="m-0" density="compact" title="SUB CATEGORY" @click="toggleItem(3)" v-if="subCategoryElements.length > 0"></v-list-item>
                  <v-list-item class="m-0" density="compact" title="CONDITION" @click="toggleItem(4)" v-if="conditionElements.length > 0"></v-list-item>
                  <v-list-item class="m-0" density="compact" title="LOCATION" @click="toggleItem(5)" v-if="locationElements.length > 0"></v-list-item>
                </v-list>
              </v-col>
              <v-col col="7" class="overflow-auto h-100">
                <!-- bidding -->
                <v-radio-group v-model="filterState.BiddingMode" density="comfortable" class="contentText-color" v-if="activeItem === 0">
                  <v-radio v-for="bidding in biddings" :key="bidding.label" v-model="selectedOptions"
                    :label="bidding.label" :value="bidding.value"></v-radio>
                </v-radio-group>

                <!-- Pricing -->
                <div class="contentText-color mt-5" v-if="activeItem === 1">
                  <div class="mx-5 pt-4">
                    <v-range-slider v-model="filterState.priceRange" color="blue-darken-4 flex-grow-1" :min="0" :max="filterStore.priceRange?.[1] ?? 12000000" step="100000"
                      thumb-label="always"></v-range-slider>
                  </div>
                </div>

                <!-- Categories -->
                <div v-if="activeItem === 2">
                  <v-checkbox density="compact" hide-details v-model="filterState.category_id" v-for="categoryElement in categoryElements" :key="categoryElement.label"
                    :label="categoryElement.label" :value="categoryElement.value"></v-checkbox>
                </div>

                <!-- Sub Categories -->
                <div v-if="activeItem === 3">
                  <v-checkbox density="compact" hide-details v-model="filterState.sub_categories" v-for="subcategoryElement in subCategoryElements"
                    :key="subcategoryElement.label" :label="subcategoryElement.label"
                    :value="subcategoryElement.value"></v-checkbox>
                </div>

                <!-- condition -->
                <div v-if="activeItem === 4">
                  <v-checkbox density="compact" hide-details v-model="filterState.condition" v-for="conditionElement in conditionElements"
                    :key="conditionElement.label" :label="conditionElement.label"
                    :value="conditionElement.value"></v-checkbox>
                </div>

                <!-- Location -->
                <div v-if="activeItem === 5">
                  <v-checkbox density="compact" hide-details v-model="filterState.location" v-for="locationElement in locationElements" :key="locationElement.label"
                    :label="locationElement.label" :value="locationElement.value"></v-checkbox>
                </div>
              </v-col>
            </v-row>

            <v-card class="pt-4 pb-4 d-flex justify-content-center">
              <v-btn variant="outlined"
                class="bid-buy-button filter-btn-border flex-grow-1 mr-5 ml-5 text-caption btn-border" size="small"
                @click="handleClose">Close
              </v-btn>

              <v-btn size="small" class="apply-changes-btn bg-theme-primary flex-grow-1 mr-5 ml-5 text-caption"
                @click="handleFilterApply">
                Apply Changes
              </v-btn>
            </v-card>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import app from './index.js';

  export default app;
</script>