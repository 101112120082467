import { biddingOptions } from '../../static';
import useFilterCom from "@/composition/utils/useFilter.js"
import { useFilter } from '../../stores';

export default {
  setup() {
  
    const { sortLotProduct,
      sortByOptions, 
      filterState,
      handleFiletrClear,
      handleFilterApply,
      category,
      subCategories,
      condition,
      biddingMode,
      categoryElements,
      subCategoryElements,
      conditionElements,
      locationElements,
      handleFilterClear,
      handleClose
    } =  useFilterCom()

    const filterStore = useFilter();

    return {
      sortByOptions,
      filterState,
      category,
      subCategories,
      condition,
      filterStore,
      location,
      biddingMode,
      categoryElements,
      subCategoryElements,
      conditionElements,
      locationElements,
      sortLotProduct,
      handleFilterApply,
      handleFilterClear,
      handleClose
    };
  },

  data() {
    return {
      activeItem: 0,
      biddings: biddingOptions,
    }
  },
  methods: {
    toggleItem(index) {
      if (this.activeItem === index) {
        this.activeItem = -1;
      } else {
        this.activeItem = index;
      }
    },
  },
};
