<template>
  <v-responsive>
    <v-app>
      <v-container fluid>
        <div class="ribbonbox" v-if="amazonRibbon === 'Amazon_Liquidation'">
          <a href="#" @click="handleDialog('', $event)"><p class="ttl-ribbonbox">Terms of purchase has been updated for Amazon lots, please <span class="cr-blue2">Click here</span> to know more. </p></a>
        </div>
        <v-row dense>
          <v-col
            cols="0"
            lg="2"
            md="2"
            sm="3"
            class="d-none d-lg-block d-md-block d-sm-none"
          >
            <filter-side-bar></filter-side-bar>
          </v-col>
          <v-col cols="12" lg="10" md="10" sm="12">
            <v-col cols="12" class="filter-section" v-if="MOQ.enabled">
              <v-div class="align-center filter-btns">
                <ul>
                  <li>
                    <button @click="toggleExpanded(filterState.lotType)" v-bind:class="{ active: filterState.BiddingMode !== 'moq' }">
                      <img
                        :src="getImage('bid')"
                        contain
                        alt="image"
                        eager
                      />
                      Bid & Win
                    </button>
                  </li>
                  <li>
                    <button @click="toggleExpanded('moq')" v-bind:class="{ active: isMoq }">
                        <img
                          :src="getImage('mov')"
                          contain
                          alt="image"
                          eager
                        />
                      MOQ
                    </button>
                  </li>
                </ul>
              </v-div>
            </v-col>

            <v-main class="pa-4">
              <v-row class="d-block">
                <v-col cols="12" lg="6">
                  <div class="d-flex align-center justify-space-between search-bar">
                    <v-text-field
                      variant="outlined"
                      placeholder="Search for lots"
                      v-model="filterState.search"
                      @keypress.enter="handleSearch"
                      hide-details
                    ></v-text-field>
                    <span>
                      <v-btn
                        variant="elevated"
                        class="mx-4 px-lg-12 search-btn"
                        color="blue"
                        size="large"
                        @click="handleSearch"
                        >Search</v-btn
                      >
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mb-6 pt-3" no-gutters>
                <v-col cols="5" sm="6" md="5" lg="6">
                  <span class="inline">
                    {{ activeLotsCount }} Live Lots ({{ biddingMode !== 'moq' ? biddingMode : 'MOQ' }})</span
                  >
                </v-col>
                <v-col
                  cols="7"
                  sm="6"
                  md="5"
                  lg="6"
                  offset-md="0"
                  class="text-right d-lg-block d-sm-none d-none sort-filter"
                >
                  <v-selector inputId="value" @update:modelValue="sortLotProduct" label="title" :reduce="opt => opt.value" v-model="filterState.selectedSortBy" :searchable="false" :options="sortByOptions" :clearable="false"></v-selector>
                </v-col>
              </v-row>
              <template v-if="!isLotLoading">
                <v-row v-if="cardDetails.length === 0 && totalPages !== 0">
                  <v-col cols="12" class="text-center">
                    <v-progress-circular
                      color="#a2a4a6"
                      class="fixed"
                      indeterminate
                      :size="52"
                      :width="7"
                    ></v-progress-circular>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col
                    class="product-card"
                    v-for="cardDetail in cardDetails"
                    :key="cardDetail.id"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                    xl="3"
                  >
                    <v-card class="mx-auto lots-outline h-100 product-box">
                      <div class="d-block marketplace-img">
                        <img
                          class="logoImg org-logo"
                          :src="imageUrl(cardDetail.org_image_url, 'org')"
                          contain
                          @error="replaceByDefault"
                          alt="org_image_url"
                          eager
                        />
                      </div>
                      <div class="pt-4 text-center d-block">
                        <p class="timer">
                          <v-icon
                            v-if="cardDetail.status"
                            icon="mdi-timer"
                            size="x-small"
                            color="blue"
                            class="mr-1 mb-1"
                          ></v-icon>
                          <TimerBadge
                            :remainingTime="cardDetail.bidRemainingTime"
                          >
                          </TimerBadge>
                        </p>
                      </div>
                      <div
                        class="cursor-pointer"
                      >
                      <a :href="getUrl(cardDetail)">
                        <div class="lots-card-image d-block logoImg w-100" @click="(e) => handleProductDetail(cardDetail, e)">
                          <v-img
                            class="text-white ma-2 h-200"
                            :src="
                              imageUrl(
                                cardDetail.lot_image_urls?.[0],
                                'lotImage'
                              )
                            "
                            contain
                          >
                            <template v-slot:error>
                              <v-img :src="lotImage"></v-img>
                            </template>
                          </v-img>
                        </div>

                        <v-card-subtitle class="d-block">
                          <div class="d-flex justify-space-evenly location-btn">
                            <v-btn
                              class="mr-1 text-capitalize flex-1-1-100"
                              variant="outlined"
                              rounded="lg"
                            >
                              <v-icon
                                size="small"
                                icon="mdi-map-marker"
                                color="blue"
                              ></v-icon>
                              {{ getValue(cardDetail, "storage_location") }}
                            </v-btn>

                            <v-btn
                              class="ml-1 text-capitalize flex-1-1-100"
                              variant="outlined"
                              rounded="lg"
                              >{{ getValue(cardDetail, "grade_name") }}</v-btn
                            >
                          </div>
                        </v-card-subtitle>
                        <!-- cards details for Hybrid bidding -->

                        <v-card-text class="flex-grow-1 pb-0">
                          <p class="title box-heading">
                            {{ getValue(cardDetail, "lot_name") }}
                            <v-tooltip
                              v-if="!isMobile()"
                              activator="parent"
                              location="auto"
                            >{{ getValue(cardDetail, "lot_name") }}</v-tooltip>
                          </p>
                        </v-card-text>

                        <v-card-text
                          v-if="['Hybrid', 'moq'].indexOf(biddingMode) > -1"
                          class="d-block py-0"
                        >
                          <div class="details-box">
                            <p class="qty py-1" v-if="!isMoq">
                              Quantity
                              <b
                                >{{
                                  getValue(cardDetail, "items_count", 0)
                                }}
                                {{ getValue(cardDetail, "items_count", 0) > 1 ? 'items' : 'item' }}</b
                              >
                            </p>
                            <p class="bid py-1">
                              {{isMoq ? 'MRP Total (per lot)' : 'MRP'}}
                              <b>₹{{ formatCurrency(getValue(cardDetail, "bid_mrp")) }}</b>
                            </p>
                            <p class="mrp py-1" v-if="!isMoq">
                              Floor Price
                              <b>₹{{ formatCurrency(getValue(cardDetail, "floor_price")) }}</b>
                            </p>
                            <p class="bid py-1" v-if="!isMoq && user?.authentication_token">
                              My Bid
                              <b v-bind:class="cardDetail.color">{{(getValue(cardDetail, "bid_amount")) }}</b>
                            </p>
                          </div>
                          <div
                            class="d-flex justify-space-between mt-2 mb-2 bidding-mode"
                          >
                            <p class="pickup">
                              <v-icon
                                size="x-large"
                                icon="mdi-truck-outline"
                                color="blue"
                              ></v-icon>
                              {{
                                getValue(cardDetail, "delivery_by", "")
                              }}
                            </p>
                            <p class="hybrid">
                              <v-icon
                                size="x-large"
                                icon="mdi-package-variant-closed"
                                color="blue"
                              ></v-icon>
                              {{ getValue(cardDetail, "bidding_method") }}
                            </p>
                          </div>
                        </v-card-text>
                        <v-card-text v-else class="d-block py-0">
                          <div class="details-box">
                            <p class="qty py-1">
                              Quantity
                              <b
                                >{{
                                  getValue(cardDetail, "items_count", 0)
                                }}
                                {{ getValue(cardDetail, "items_count", 0) > 1 ? 'items' : 'item'}}</b
                              >
                            </p>
                            <p class="mrp py-1">
                              {{isMoq ? 'MRP Total (per lot)' : 'MRP'}}
                              <b>₹{{ formatCurrency(getValue(cardDetail, "bid_mrp")) }}</b>
                            </p>
                             <p class="bid py-1" >
                              Current Bid
                              <b>₹{{ formatCurrency(getValue(cardDetail, "currentBid")) }}</b>
                            </p>
                            <p class="bid py-1" v-if="user?.authentication_token">
                              My Bid
                              <b v-bind:class="cardDetail.color">{{(getValue(cardDetail, "bid_amount")) }}</b>
                            </p>
                          </div>
                          <div
                            class="d-flex justify-space-between mt-2 mb-2 bidding-mode"
                          >
                            <p class="pickup">
                              <v-icon
                                size="x-large"
                                icon="mdi-truck-outline"
                                color="blue"
                              ></v-icon>
                              {{
                                getValue(cardDetail, "delivery_by", "")
                              }}
                            </p>
                            <p class="hybrid">
                              <v-icon
                                size="x-large"
                                icon="mdi-package-variant-closed"
                                color="blue"
                              ></v-icon>
                              {{
                                getValue(cardDetail, "bidding_method", "Open")
                              }}
                            </p>
                          </div>
                        </v-card-text>

                      </a>

                      </div>
                      <a :href="getUrl(cardDetail)">
                      <v-card-text class="d-block pt-0" @click="(e) => handleProductDetail(cardDetail, e)">
                        <template v-if="user?.authentication_token">
                          <div
                            v-if="cardDetail.bid_not_start"
                            class="box-action"
                          >
                            <v-btn
                              color="blue-darken-1 flex-grow-1 mb-2"
                              size="large"
                              width="100%"
                              class="bid-buy-button"
                              variant="flat"
                              >Bid not started</v-btn
                            >
                          </div>
                          <div
                            v-else-if="!cardDetail.bid_finished"
                            class="box-action"
                          >
                            <v-btn
                              v-if="filterState.BiddingMode !== 'moq' && !isGreaterToBuy(cardDetail)"
                              color="flex-grow-1 mb-2"
                              size="large"
                              width="100%"
                              class="bid-buy-button primary-btn"
                              variant="flat"
                              @click="handleProductDetail(cardDetail)"
                            >
                              {{
                                biddingMode == "Hybrid"
                                  ? "PLACE BID"
                                  : `Bid @₹${formatCurrency(getValue(
                                      cardDetail,
                                      "place_bid_amount"
                                    ))} (Save ${ formatCurrency(getValue(
                                      cardDetail,
                                      "bidOffPercentage",
                                      0
                                    ))}%)`
                              }}
                            </v-btn>
                            <v-btn
                              variant="outlined"
                              size="large"
                              width="100%"
                              color="flex-grow-1 mb-2"
                              :class="isMoq ? 'bid-buy-button primary-btn mb-2' : 'primary-btn-border mb-2'"
                              @click="handleProductDetail(cardDetail)"
                              >BUY @₹{{
                                formatCurrency(getValue(cardDetail, isMoq ? "moq_lot_lowest_buy_price" : "buy_now_price", 0))
                              }}
                              (Save
                              {{
                                getValue(cardDetail, "offPercentage", 0)
                              }}%)</v-btn
                            >
                          </div>
                          <div
                            v-else-if="cardDetail.bid_finished"
                            class="box-action"
                          >
                            <v-btn
                              color="flex-grow-1 mb-2"
                              size="large"
                              width="100%"
                              class="bid-buy-button primary-btn"
                              variant="flat"
                              >Bid Expired</v-btn
                            >
                          </div>
                        </template>
                      </v-card-text>
                      </a>
                      <!-- cards details for All bidding -->
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="totalPages === 0 && cardDetails.length === 0">
                  <v-col class="text-center"> No data found! </v-col>
                </v-row>

                <v-row>
                  <v-col v-if="showPagination">
                    <v-pagination
                      v-model="page"
                      :length="totalPages"
                      :total-visible="6"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </template>
              <template v-if="isLotLoading">
                <v-progress-linear
                  color="#1962D5"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </template>
            </v-main>
          </v-col>
        </v-row>
      </v-container>
      <div class="d-lg-none d-md-none d-sm-block d-block">
        <footer-filter></footer-filter>
      </div>

      <v-dialog
        v-model="itemData.dialog"
        width="800"
        content-class="terms-modal"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> Terms of Purchase</span>
            <v-btn icon variant="text" @click="itemData.dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-div class="tnc-container">
            <p v-html="itemData.tcData"></p>
          </v-div>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn variant="outlined" @click="handleAccept(false)">Back</v-btn>
            <v-btn variant="outlined" @click="handleAccept(false)">Skip</v-btn>
            <v-btn class="bg-blue" variant="text" @click="handleAccept(true)">
              Accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </v-responsive>
</template>

<script>
import FooterFilter from "../footer/FooterFilter.vue";
import FilterSideBar from "../sidebar/FilterSideBar.vue";
import { formatCurrency } from "../../misc/formatter.js";
import { isMobile } from "../../misc/index";
import {
  usePaginationStore,
  uselotProducts,
  useAuth,
  loaderStore,
  useFilter,
  useCommonStore,
  useAlertStore,
} from "../../stores";
import {fetchOrganisationTc} from "../../Actions/MarketPlaceActions";
import { computed, watch, ref, onUpdated, onMounted, onUnmounted, reactive } from "vue";
import { useRoute } from "vue-router";
import TimerBadge from "./TimerBadge.vue";
import orgImage from "@/../assets/images/blubirch_logo.png";
import lotImage from "@/../assets/images/Box.svg";
import bidIcon from "@/../assets/images/bidIcon.svg";
import movIcon from "@/../assets/images/movIcon.svg";

import useFilterCom from "@/composition/utils/useFilter.js";
import { getLotFilterQuery } from "../../misc/index";
import configuration from "../../config.json";
import {
  statusList,
} from "../../static";

export default {
  setup() {
    const paginationstore = usePaginationStore();
    const lotStore = uselotProducts();
    const loader = loaderStore();
    const authStore = useAuth();
    const filterStore = useFilter();
    const commonStore = useCommonStore();
    const route = useRoute();
    const isLotLoading = ref(true);
    const currentTime = ref(new Date().getTime());
    const alertStore = useAlertStore();

    const { sortLotProduct, updatePath, handleSearch, sortByOptions, filterState } = useFilterCom();
    const page = computed({
      get: () => paginationstore.page,
      set: (value) => {
        paginationstore.setPage(value);
        updatePath()
      },
    });
    const totalPages = computed(() => paginationstore.totalPages);
    const totalItems = computed(() => paginationstore.totalItems);
    const activeLotsCount = computed(() => getBiddingMode() === 'Open' ? lotStore.open_active_lots : lotStore.active_lots);
    const cardDetails = computed(() => {
      const products = getBiddingMode() === 'Open' ? lotStore.openCartProduct :  lotStore.cartProduct;
      return products.map((item) => ({
        ...item,
        bid_finished: (item.status === statusList.InProgress || item.status === statusList.InProgress_2 || item.status === statusList.NotStarted)
            ? !item.bidRemainingTime
            : true,
        bid_not_start: item.futureBid,
      }))
    }
    );
    const user = computed(() => authStore.user);
    const showPagination = computed(() => totalPages.value > 0);

    const imageUrl = (value, type) => {
      return value ? value : type === "org" ? "" : lotImage;
    };

    const itemData = reactive({
      dialog: false,
      tcData: {}
    });
    const handleDialog = (value = "", event) => {
      event.preventDefault();
      loader.setLoading(true);
      fetchOrganisationTc(6209).then((res) => {
        if (res.status === 200) {
          itemData.tcData = res?.data?.terms_of_purchase.terms_and_conditions;
          setTimeout(() => {
            loader.setLoading(false);
          }, 1000);
          itemData.dialog = true;
        } else {
          loader.setLoading(false);
          alertStore.setAlertMessage("error", res.data?.errors, true);
        }
      });
    };
    const handleAccept = (accept = false) => {
      itemData.dialog = false;
      if (accept) {
        alertStore.setAlertMessage('success', 'Updated Terms & Conditions accepted successfully.', true);
      }
    };

    const getValue = (key, attrType, defaultVal = "N/A") => {
      if (attrType === "bid_amount") {
        return (key?.[attrType] && (key?.[attrType] !== "0")) ? "₹" + formatCurrency(key?.[attrType]) : 'Not Bidded';
      } else {
        return key?.[attrType] || defaultVal;
      }
    };

    const getBiddingMode = () => {
      return route.query.BiddingMode || "Hybrid";
    }

    const getPage = () => {
      return route.query.page ?? 1;
    }

    const biddingMode = computed(() => getBiddingMode());

    const setLotProducts = (isInterval = false, field) => {
      const queryData = getLotFilterQuery();
      if(route.path.match('/products')) {
        if (getBiddingMode() !== "Open") {
          lotStore.setLotProducts(getLotFilterQuery(), isInterval);
        } else {
          lotStore.getOpenLotProducts(getLotFilterQuery(), isInterval);
        }
      }
    };

    const bidAndBuyAction = (dailogdDetail) => {
      if (dailogdDetail.type === "bid") {
        lotStore.placeBid(dailogdDetail.detail);
      } else {
        lotStore.placeBuy(dailogdDetail.detail.id);
      }
    };

    let intervalId;

    const getImage = (type) => {
      return type === 'bid' ? bidIcon : movIcon;
    }

    const amazonRibbon = ref('');
    onMounted(() => {
      lotStore.setOrganizationId(route.params.organization_id)
      lotStore.setSuccess();
      loader.setLotLoading(true);

      if (route.params.organization_id === 'Amazon_Liquidation') {
        amazonRibbon.value = 'Amazon_Liquidation';
        lotStore.setAmazonRibbon('Amazon_Liquidation');
      } else {
        amazonRibbon.value = '';
        lotStore.setAmazonRibbon('');
      }

      if(route.path.match('/products')) {
        setLotProducts();
        intervalId = setInterval(() => {
          setLotProducts(true);
        }, 15000);
      }

      const bidModeVal = getBiddingMode();
      if (bidModeVal !== "Open") {
        filterStore.lotFilterOptions(bidModeVal);
      } else {
        filterStore.scbLotFilterOptions();
      }
    });

    onUnmounted(() => {
      clearInterval(intervalId)
      clearInterval(intervalObj)
    });

    onUpdated(() => {
      if (route.path !== route.path && route.path.match('/products')) {
        setLotProducts();
      }
    });

    watch(() => route.query, async (newValue, oldValue) => {
      await lotStore.setOrganizationId(route?.params?.organization_id)
      if(route.path.match('/products')) {
        setLotProducts()
        paginationstore.setPage(getPage());
        biddingMode.value = getBiddingMode();
        if (biddingMode.value !== "Open") {
          filterStore.lotFilterOptions(biddingMode.value);
        } else {
          filterStore.scbLotFilterOptions();
        }
      }
    })

    let intervalObj;
    watch(cardDetails, () => {
      if (cardDetails.value.length > 0) {
        intervalObj = setInterval(() => {
          let lot = cardDetails.value.find((item) => {
            const StartTime = new Date(item.start_date).getTime();
            const endTime = new Date(item.end_date).getTime();

            const currentTime = new Date().getTime();
            const threshold = 1000; // 1 second threshold
            if (Math.abs(StartTime - currentTime) <= threshold) {
              return item;
            } else if (Math.abs(endTime - currentTime) <= threshold) {
              return item;
            }
          });
          if (lot?.id) {
            currentTime.value = new Date().getTime();
          }
        }, 1000)
      }
    });

    watch(user, () => {
     if(route.path.match('/products')) {
        setLotProducts();
     }
    });

    watch(loader, () => {
      isLotLoading.value = loader.lotLoader;
    });

    const toggleExpanded = (value) => {
      Object.assign(filterState, {...filterState.value, BiddingMode: value, lotType: filterState.BiddingMode})
    }

    const isMoq = computed(() => filterState.BiddingMode === 'moq');
    const isGreaterToBuy = (detailData) => {
      return parseInt(detailData?.place_bid_amount) >= parseInt(detailData?.buy_now_price)
    }

    const { MOQ, Hybrid } = configuration.Lots;
    return {
      page,
      sortByOptions,
      totalPages,
      filterState,
      toggleExpanded,
      cardDetails,
      isGreaterToBuy,
      totalItems,
      user,
      MOQ,
      Hybrid,
      formatCurrency,
      lotImage,
      isMoq,
      biddingMode,
      showPagination,
      activeLotsCount,
      imageUrl,
      getImage,
      getValue,
      handleSearch,
      isLotLoading,
      setLotProducts,
      sortLotProduct,
      bidAndBuyAction,
      itemData,
      amazonRibbon,
      handleDialog,
      handleAccept,
    };
  },
  components: {
    FilterSideBar,
    FooterFilter,
    TimerBadge,
  },
  methods: {
    isMobile: isMobile,
    handleProductDetail(detail, e) {
      if(e) {
        e.preventDefault()
      }

      let url = detail?.bid_id
        ? `/product_detail/${detail.id}?bid_id=${detail?.bid_id}`
        : `/product_detail/${detail.id}`;
      this.$router.push(url);
    },
    getUrl(detail) {
      let url = detail?.bid_id
        ? `/product_detail/${detail.id}?bid_id=${detail?.bid_id}`
        : `/product_detail/${detail.id}`;
      return url;
    },
    replaceByDefault(e) {
      e.target.src = ""
    },
    handleSortData(sort) {
      this.sortLotProduct(sort);
    }
  },
};
</script>
